'use client';
import { useCallback, useEffect, type ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useRefreshToken } from 'app/api/internal/useRefreshToken';
import { tokenInfoNoInterceptor as getTokenInfo } from 'app/api/no-interceptor/tokenInfo';
import { resetTokenInfo, setTokenInfo } from '../features/auth/authSlice';

const REFRESH_TIME_BUFFER_IN_MILLISECONDS = 60000; // Refresh 1 minute before expiry

export function TokenProvider({ children }: { children: ReactNode }) {
  const tokenInfo = useAppSelector((state) => state.auth.tokenInfo);
  const [refreshToken, releaseLock] = useRefreshToken();
  const dispatch = useAppDispatch();

  const fetchTokenInfo = useCallback(async () => {
    try {
      const response = await getTokenInfo();
      if (response) {
        dispatch(setTokenInfo(response));
      } else {
        dispatch(resetTokenInfo());
      }
    } catch {
      dispatch(resetTokenInfo());
    }
  }, [dispatch]);

  // Set the token info on mount
  useEffect(() => {
    void fetchTokenInfo();
  }, [fetchTokenInfo]);

  // Refresh the token info when the token is about to expire
  useEffect(() => {
    if (!tokenInfo.expiry) {
      return;
    }
    // Time until access token expires in milliseconds
    const expiresIn = tokenInfo.expiry - Date.now();
    // Refresh 1 minute before expiry. If access token expiry is <= 1 minute, then refresh half that time instead (minimum 1s).
    // The token expiry should not be configured to be less than (or close to) a minute realistically.
    const refetchTime = Math.max(
      expiresIn > REFRESH_TIME_BUFFER_IN_MILLISECONDS ? expiresIn - REFRESH_TIME_BUFFER_IN_MILLISECONDS : expiresIn / 2,
      1000
    );

    const refreshTimeout = setTimeout(() => {
      // Errors will be handled by the interceptor
      void refreshToken();
    }, refetchTime);
    return () => {
      clearTimeout(refreshTimeout);
      releaseLock();
    };
  }, [tokenInfo.expiry, refreshToken, releaseLock]);

  return <>{children}</>;
}
