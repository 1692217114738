/**
 * This refresh page will always happen after axios has failed without possible recovery, so it needs to be a login page with a redirect.
 * Otherwise, trying to refresh a logged in route could lead to infinite attempts to token refresh
 */
export const sendToLogin = () => {
  const currentUrl = new URL(window.location.href);
  const redirectUrl = new URL(
    `/login?redirect=${encodeURIComponent(`${currentUrl.pathname}${currentUrl.search}`)}`,
    currentUrl
  );
  window.location.href = redirectUrl.href;
};
