'use client';
import { axiosInstance } from '@cxnpl/api/axios';
import { useEffect, useMemo, type ReactNode } from 'react';
import { Provider, type ProviderProps } from 'react-redux';
import { v4 as uuid } from 'uuid';
import type { AppStore } from 'app/store';
import { setupStore } from 'app/store';
import { setCustomerNumber, setDeviceId } from 'packages/app/features/auth/authSlice';
import { setEmbeddedMode } from 'packages/app/features/embedded/embeddedSlice';
import configureAxios from '../../services/axiosConfiguration/configureAxios';
import { storage } from './deviceStorage';
import { sendToLogin } from './sendToLogin';

export const defaultStore = setupStore();

export interface StoreProviderProps extends Partial<ProviderProps> {
  children: ReactNode;
  isEmbedded?: boolean;
}

export function StoreProvider({ store: initialStore, children, isEmbedded = false }: StoreProviderProps) {
  const store = useMemo(() => (initialStore as AppStore | undefined) ?? defaultStore, [initialStore]);

  // Initializes store with asynchronous data
  useEffect(() => {
    const initStore = async () => {
      // Get Device id
      let deviceId = await storage.deviceId.get();
      if (!deviceId) {
        deviceId = await storage.deviceId.set(uuid());
      }
      store.dispatch(setDeviceId(deviceId));

      // Get Customer number
      const customerNumber = await storage.customerNumber.get();
      if (customerNumber) {
        store.dispatch(setCustomerNumber(customerNumber));
      }
    };

    void initStore();
  }, [store]);

  // Set is embedded
  useEffect(() => {
    store.dispatch(setEmbeddedMode(isEmbedded));
  }, [isEmbedded, store]);

  useEffect(() => {
    const { requestInterceptorId, responseInterceptorId } = configureAxios(axiosInstance, store, sendToLogin);

    return () => {
      axiosInstance.interceptors.response.eject(requestInterceptorId);
      axiosInstance.interceptors.response.eject(responseInterceptorId);
    };
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
}
