import { baseUrl } from '@cxnpl/api/baseUrl';
import axios from 'axios';
import type { AuthenticationResultType, Refresh200 } from '@cxnpl/api/api.schemas';
import { appClient } from 'app/utils/appClient';
import type { AppStore } from 'packages/app/store';

/**
 * Refresh user token using a clean axios instance.
 * This does not have the interceptors attached to it and is intended to refresh the token
 * without any side effects. This method is NOT intended to be called within components.
 * Please instead use `useRefreshToken` from `app/api/internal/useRefreshToken.ts`.
 *
 * @param entityId - The entity ID of the business to refresh the token for.
 * @param auth0Enabled - Whether Auth0 is enabled.
 */
export async function refreshTokenNoInterceptor(
  { entityId, auth0Enabled, store }: { entityId?: string; auth0Enabled: boolean; store?: AppStore } = {
    auth0Enabled: false,
  }
): Promise<AuthenticationResultType | Refresh200> {
  // Toggle refresh endpoint based on whether Auth0 is enabled
  const refreshUrl = auth0Enabled ? '/v2/session/refresh' : '/login/refresh';
  // not using axiosInstance means it will not hit interceptors

  const authInfo = store?.getState().auth;

  const resp = await axios({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-PROTECTION': '1',
      ...(authInfo && appClient === 'MOBILE'
        ? {
            Authorization: auth0Enabled ? authInfo.accessToken : authInfo.idToken,
            AccessAuthorizationKey: auth0Enabled
              ? undefined // Not needed for any Auth0 endpoints
              : authInfo.accessToken,
            EntityAccountAuthorizationKey: authInfo.tokenInfo.entityAccountId,
            DeviceId: authInfo.deviceId,
          }
        : {}),
    },
    withCredentials: true,
    method: 'POST',
    url: refreshUrl,
    data: {
      entityId,
      appClient,
      refreshToken: appClient === 'MOBILE' ? authInfo?.refreshToken : undefined,
    },
  });
  return resp.data as AuthenticationResultType | Refresh200;
}
