import { tokenInfoNoInterceptor } from 'app/api/no-interceptor/tokenInfo';
import { resetTokenInfo, setTokenInfo } from 'app/features/auth/authSlice';
import type { UpdateAuthInfoParams } from './types';

/**
 * Function that updates the auth info in the store
 */
export const updateAuthInfo = async ({ store, refreshResponse: _refreshResponse }: UpdateAuthInfoParams) => {
  // Get token info straight from cookies instead of response
  const tokenInfo = await tokenInfoNoInterceptor();
  if (tokenInfo) {
    store.dispatch(setTokenInfo(tokenInfo));
  } else {
    store.dispatch(resetTokenInfo());
  }
};
