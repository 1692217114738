import { shutdown as intercomShutdown } from '@intercom/messenger-js-sdk';
import { logoutNoInterceptor } from 'app/api/no-interceptor/logout';
import { resetTokenInfo } from 'app/features/auth/authSlice';
import type { AppStore } from 'app/store';

export const logoutAndRefresh = async ({ store, sendToLogin }: { store: AppStore; sendToLogin: () => void }) => {
  try {
    const auth0Enabled = store.getState().featureFlag.enableV2Auth0;
    store.dispatch(resetTokenInfo());
    intercomShutdown();
    await logoutNoInterceptor(auth0Enabled);
  } catch (e) {
    // Do nothing
  } finally {
    sendToLogin();
  }
};
