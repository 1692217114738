'use client';

import { Button, FormField, H1, SchemaForm, Text, XStack, YStack, useForm } from '@cxnpl/ui';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import type { UseFormReturn } from 'react-hook-form';
import { useMemo } from 'react';
import { useCountdown } from 'app/hooks';
import i18n from 'app/i18n/i18n.config';

export const MfaFormSchema = z.object({
  code: FormField.PasswordCode((zString) =>
    zString({
      required_error: `${i18n.t('auth.components.mfaForm.securityCodeRequiredErrorMessage')}`,
    })
      .min(1, `${i18n.t('auth.components.mfaForm.securityCodeRequiredErrorMessage')}`)
      .max(6)
      .regex(/^[0-9]{6}$/, `${i18n.t('auth.components.mfaForm.invalidSecurityCodeErrorMessage')}`)
  ).describe(`${i18n.t('auth.components.mfaForm.securityCodeInputLabel')}`),
});

export type MfaFormFields = z.infer<typeof MfaFormSchema>;

type MfaFormHelpers = UseFormReturn<MfaFormFields> & {
  startCountdown: ReturnType<typeof useCountdown>['startCountdown'];
};

export interface MfaFormProps {
  /**
   * Optional title to show above the form.
   */
  title?: string;
  /**
   * Optional description to show above the form.
   */
  description?: string;
  /**
   * Callback to call when the confirm button is pressed.
   *
   * @param values - Form values.
   * @param helpers - Form instance helper functions from `useForm` (`react-hook-form`), as well as `startCountdown` which sets the resend countdown.
   */
  onSubmit: (values: MfaFormFields, helpers: MfaFormHelpers) => void | Promise<void>;
  /**
   * Callback to call when the resend button is pressed. If not provided, the resend button will not be shown.
   *
   * @param helpers - Form instance helper functions from `useForm` (`react-hook-form`), as well as `startCountdown` which sets the resend countdown.
   */
  onResend?: (helpers: MfaFormHelpers) => void | Promise<void>;
  /**
   * Callback to call when the cancel button is pressed. If not provided, the cancel button will not be shown.
   *
   * @param helpers - Form instance helper functions from `useForm` (`react-hook-form`), as well as `startCountdown` which sets the resend countdown.
   */
  onCancel?: (helpers: MfaFormHelpers) => void;
  /**
   * Whether to automatically call onSubmit when the code is fully entered. Defaults to true.
   */
  autoSubmit?: boolean;
  /**
   * Optional form instance to use. Use if you want to control the form externally.
   */
  form?: UseFormReturn<MfaFormFields>;
  /**
   * Optional test ID for the form. Defaults to 'mfa-form'.
   */
  testID?: string;
  /**
   * Button label for the submit button, which calls onSubmit. Defaults to 'Confirm'.
   */
  submitButtonLabel?: string;
  /**
   * Button label for the cancel button, which calls onCancel. Defaults to 'Cancel'.
   */
  cancelButtonLabel?: string;
  /**
   * Whether the form is disabled. Defaults to false.
   */
  disabled?: boolean;
}

export const MfaForm = ({
  title,
  description,
  onSubmit: onSubmitCallback,
  onResend,
  onCancel,
  autoSubmit = true,
  form: controlledForm,
  testID,
  submitButtonLabel,
  cancelButtonLabel,
  disabled = false,
}: MfaFormProps) => {
  const { t } = useTranslation();
  const { startCountdown, countdown } = useCountdown();

  const _form = useForm(MfaFormSchema, {
    reValidateMode: 'onSubmit',
  });
  const form = controlledForm ?? _form;
  const isDisabled = form.formState.isSubmitting || disabled;

  const helpers = useMemo<MfaFormHelpers>(() => ({ ...form, startCountdown }), [form, startCountdown]);

  const onSubmit = async (values: MfaFormFields) => {
    await onSubmitCallback(values, helpers);
  };

  const submitForm = () => {
    void form.handleSubmit(onSubmit)();
  };

  return (
    <YStack testID={testID || 'mfa-form'} gap="$space.2xl" width="100%">
      {title ? <H1 color="$foreground/surface-default">{title}</H1> : null}
      {description ? (
        <Text variant="bodyMedium" flexWrap="wrap" color="$foreground/surface-subdued" whiteSpace="pre-wrap">
          {description}
        </Text>
      ) : null}
      <SchemaForm
        form={form}
        onSubmit={onSubmit}
        schema={MfaFormSchema}
        formProps={{
          disabled: isDisabled,
        }}
        props={{
          code: {
            isNumericOnly: true,
            focusAfterSubmit: true,
            autoFocus: true,
            alwaysFocused: false,
            autoSubmit: autoSubmit ? submitForm : undefined,
            onChangeText: () => {
              form.clearErrors();
            },
          },
        }}
      >
        {(fields) => (
          <YStack gap="$space.lg">
            {fields.code}
            {onResend ? (
              <YStack>
                {countdown === 0 ? (
                  <Text
                    variant="linkMedium"
                    width="max-content"
                    link
                    color="$button/color/button-primary-text"
                    onPress={() => {
                      void onResend(helpers);
                    }}
                    disabled={isDisabled}
                  >
                    {t('auth.components.mfaForm.resendLink')}
                  </Text>
                ) : (
                  <XStack gap="$space.xs">
                    <Text color="$foreground/surface-subdued">{t('auth.components.mfaForm.resendCountdownText')}</Text>
                    <Text color="$foreground/surface-default">{countdown}</Text>
                  </XStack>
                )}
              </YStack>
            ) : null}
          </YStack>
        )}
      </SchemaForm>
      <XStack alignItems="center" gap="$space.sm" justifyContent="space-between" flexWrap="wrap">
        <XStack flexGrow={onCancel ? 1 : 0} width="100%" $tablet={{ width: 'auto' }}>
          <Button
            fullWidth
            testID={`${testID || 'mfa-form'}-submit`}
            loading={isDisabled}
            disabled={isDisabled}
            mode="primary"
            variant="filled"
            size="lg"
            onPress={() => {
              submitForm();
            }}
          >
            {submitButtonLabel || t('common.buttons.confirm')}
          </Button>
        </XStack>
        {onCancel ? (
          <XStack flexGrow={1}>
            <Button
              fullWidth
              disabled={isDisabled}
              mode="primary"
              variant="outlined"
              size="lg"
              onPress={() => {
                onCancel(helpers);
              }}
            >
              {cancelButtonLabel || t('common.buttons.cancel')}
            </Button>
          </XStack>
        ) : null}
      </XStack>
    </YStack>
  );
};
