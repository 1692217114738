'use client';
import { useCallback, useEffect, type ReactNode } from 'react';
import Intercom, { hide } from '@intercom/messenger-js-sdk';
import { getGetIntercomHmacQueryOptions } from '@cxnpl/api/help/help';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'app/store';

export interface IntercomProviderProps {
  children: ReactNode;
}

export const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const tokenInfo = useAppSelector((state) => state.auth.tokenInfo);
  const queryClient = useQueryClient();

  const onLogin = useCallback(
    async (userId: string) => {
      const data = await queryClient.fetchQuery(getGetIntercomHmacQueryOptions());

      Intercom({
        app_id: process.env.INTERCOM_APP_ID ?? '',
        region: 'ap',
        hide_default_launcher: true,
        vertical_padding: 100,
        user_hash: data.web,
        user_id: userId,
      });
    },
    [queryClient]
  );

  const onLogout = () => {
    hide();
  };

  useEffect(() => {
    if (tokenInfo.userId) {
      void onLogin(tokenInfo.userId);
    } else {
      onLogout();
    }
  }, [tokenInfo, onLogin]);

  return children;
};
