import axios from 'axios';
import type { TokenInfo } from 'app/features/auth/types';

export async function tokenInfoNoInterceptor(): Promise<TokenInfo | null> {
  // not using axiosInstance means it will not hit interceptors
  try {
    const response = await axios<TokenInfo | null>({
      method: 'GET',
      withCredentials: true,
      url: `/webapi/tokenInfo`,
    });
    return response.data;
  } catch (e) {
    return null;
  }
}
