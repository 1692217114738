import type { AxiosRequestHeaders } from 'axios';
import type { AppStore } from 'packages/app/store';
import { EMBEDDED_HEADER } from 'packages/app/utils/isEmbedded/types';

/**
 * Function that adds headers to the axios config. Is mutating the headers object so has no return variable
 */
export const setHeaders = ({ headers, store }: { headers: AxiosRequestHeaders; store: AppStore }) => {
  const state = store.getState();
  const authInfo = state.auth;
  const isEmbedded = state.embedded.isEmbedded;

  if (isEmbedded) {
    headers[EMBEDDED_HEADER] = isEmbedded;
  }

  if (authInfo.deviceId) {
    headers.deviceId = authInfo.deviceId;
  }
};
