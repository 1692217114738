'use client';
import { useEffect, type ReactNode } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useRouter } from '@cxnpl/router';
import { useRevokeWebAppSession } from '@cxnpl/api/users/users';
import { shutdown as intercomShutdown } from '@intercom/messenger-js-sdk';
import { appClient } from 'app/utils/appClient';
import { useAppDispatch, useAppSelector } from '../../store';
import { setInactivityLogout } from '../../features/auth/authSlice';

export interface InactivityTimeoutProviderProps {
  timeout: number;
  children: ReactNode;
}

export function InactivityTimeoutProvider({ timeout, children }: InactivityTimeoutProviderProps) {
  const router = useRouter();
  const logoutUser = useRevokeWebAppSession();
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.auth.tokenInfo);
  const isLoggedIn = Boolean(userId);

  const onIdle = () => {
    intercomShutdown();
    logoutUser
      .mutateAsync({ data: { appClient } })
      .catch(() => {
        // TODO: should this show an error anywhere?
      })
      .finally(() => {
        dispatch(setInactivityLogout(true));
        router.push('/login');
      });
  };

  const idleTimer = useIdleTimer({ timeout, onIdle, stopOnIdle: true });

  useEffect(() => {
    if (isLoggedIn) {
      idleTimer.start();
    } else {
      idleTimer.pause();
    }
  }, [isLoggedIn, idleTimer]);

  return <>{children}</>;
}
