'use client';
import React, { useCallback, useState, useContext, useEffect, createContext } from 'react';
import { useGetFeatureToggles } from '@cxnpl/api/config/config';
import { Loader } from '@cxnpl/ui';
import { setFlags, type Flags } from 'app/features/featureFlag/featureFlagSlice';
import { useAppDispatch, useAppSelector } from '../../store';

interface FlagContextValue {
  flags: Flags;
}

const FlagContext = createContext<FlagContextValue | undefined>(undefined);

interface FlagProviderProps {
  children?: React.ReactNode;
  config?: {
    /**
     * How often we refresh the feature flags from the backend
     * in milliseconds
     */
    refreshFrequencyMilliseconds?: number;
  };
}

const FLAG_REFRESH_INTERVAL_MILLISECONDS = 60 * 1000;

export const haveValuesChanged = (currentFlags: Flags, newFlags: Flags) => {
  return (
    // check if the lengths have changed
    Object.keys(currentFlags).length !== Object.keys(newFlags).length ||
    // check if new keys and values differ
    Object.entries(newFlags).some(([flag, newValue]) => newValue !== currentFlags[flag])
  );
};

export const FlagProvider: React.FC<FlagProviderProps> = ({ children, config }) => {
  const flagContext: FlagContextValue | undefined = useContext(FlagContext);
  const [initialized, setInitialized] = useState(false);

  const userId = useAppSelector((state) => state.auth.tokenInfo).userId;
  const { refetch: refetchFeatureFlags } = useGetFeatureToggles();
  const dispatch = useAppDispatch();
  const flags = useAppSelector((state) => state.featureFlag);

  const refreshFlags = useCallback(async () => {
    try {
      const { data } = await refetchFeatureFlags();
      // return new flag values only if they've changed, so we don't re-render everything in the provider
      if (data && haveValuesChanged(flags, data.featureToggles as Flags)) {
        dispatch(setFlags(data.featureToggles as Flags));
      }
      if (data?.featureToggles) {
        setInitialized(true);
      }
    } catch (error) {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- The `userId` is required as we need to reload the state of feature flags when the user is logged in or logged out
  }, [dispatch, flags, refetchFeatureFlags, userId]);

  /**
   * Setup interval which refreshes the stored feature toggle values
   */
  useEffect(() => {
    // Run immediately upon mounting:
    void refreshFlags();

    // Then set the interval to run at the desired frequency:
    const refreshInterval = setInterval(
      () => void refreshFlags,
      config?.refreshFrequencyMilliseconds ?? FLAG_REFRESH_INTERVAL_MILLISECONDS
    );

    // Cleanup interval on unmounting:
    return () => {
      clearInterval(refreshInterval);
    };
  }, [refreshFlags, config?.refreshFrequencyMilliseconds]);

  if (flagContext !== undefined) {
    throw new Error('There can only be one FlagProvider in the components tree');
  }
  if (!initialized) {
    return <Loader />;
  }

  return <FlagContext.Provider value={{ flags }}>{children}</FlagContext.Provider>;
};
